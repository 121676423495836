import { useContext } from 'react'
import { SiteContext } from '../context/SiteContext'

const GetHotSeatName = () => {

  const salaId = useContext(SiteContext)

  switch (salaId) { 
    case 63:
      return 'BIG WIN'
    case 65:
      return 'HOT WIN'
    case 80:
      return 'HAPPY HOURS'
    case 81:
      return 'HAPPY HOURS'
    default:
      return 'SORTEO HOT SEAT'
  }
}

export default GetHotSeatName